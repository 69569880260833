import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// Layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// Components
import LoadingScreen from '../components/LoadingScreen';
import UserModel from '../@types/models/UserModel';

type Props = {
  userModel?: UserModel | null | undefined;
};

// ----------------------------------------------------------------------

export default function Router({ userModel }: Props): React.ReactElement {
  const isLogged = Boolean(userModel?.isConnected || userModel?.isSigned);
  return (
    <>
      {useRoutes([
        // {
        //   path: '/',
        //   element: <Navigate to="/dashboard" replace />,
        // },
        {
          path: '/',
          element: isLogged ? <Navigate to="/dashboard" replace /> : <LandingPage />,
        },
        {
          path: '/',
          element: <DashboardLayout />,
          children: [
            { element: <Dashboard /> },
            { path: 'dashboard', element: <Dashboard /> },
            { path: 'campaigns', element: <Campaigns /> },
            { path: 'campaigns/new', element: <NewCampaign /> },
            { path: 'campaign/:id', element: <Campaign /> },
            { path: 'launchpad', element: <LaunchPad /> },
            { path: 'launchpad/:id', element: <Project /> },
            { path: 'socialfarming', element: <SocialFarming /> },
            // Community
            { path: 'influencers', element: <Influencers /> },
            { path: 'advisors', element: <Influencers /> },
          ],
        },
        {
          path: '*',
          element: <LogoOnlyLayout />,
          children: [
            { path: '404', element: <NotFound /> },
            { path: '*', element: <Navigate to="/404" replace /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
      ])}
    </>
  );
}

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

const LandingPage = Loadable(lazy(() => import('../pages/LandingPage')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Campaigns = Loadable(lazy(() => import('../pages/Campaigns')));
const NewCampaign = Loadable(lazy(() => import('../pages/NewCampaign')));
const Campaign = Loadable(lazy(() => import('../pages/Campaign')));
const Influencers = Loadable(lazy(() => import('../pages/Influencers')));
const LaunchPad = Loadable(lazy(() => import('../pages/LaunchPad')));
const SocialFarming = Loadable(lazy(() => import('../pages/SocialFarming')));
const Project = Loadable(lazy(() => import('../pages/Project')));

const NotFound = Loadable(lazy(() => import('../pages/Page404')));
