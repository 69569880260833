// @mui icons.
import DashboardIcon from '@mui/icons-material/Dashboard';
import WindPowerIcon from '@mui/icons-material/WindPower';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ShareIcon from '@mui/icons-material/Share';
import PsychologyIcon from '@mui/icons-material/Psychology';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
// components
// import SvgIconStyle from '../../../components/SvgIconStyle';
// type
import { NavSectionConfigProps } from '../../../components/nav-section/type';

// ----------------------------------------------------------------------

// const getIcon = (name: string) => (
//   <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
// );

const ICONS = {
  dashboard: <DashboardIcon />,
  campaigns: <WindPowerIcon />,
  distribution: <AccountTreeIcon />,
  socialFarming: <ShareIcon />,
  //
  influencers: <ConnectWithoutContactIcon />,
  advisors: <PsychologyIcon />,
};

export const getNavConfig = (): Array<NavSectionConfigProps> => [
  {
    items: [
      { title: 'Dashboard', path: '/dashboard', icon: ICONS.dashboard },
      { title: 'Campaigns', path: '/campaigns', icon: ICONS.campaigns },
      { title: 'Launch Pad', path: '/launchpad', icon: ICONS.distribution },
      { title: 'Social Farming', path: '/socialfarming', icon: ICONS.socialFarming },
    ],
  },
  // {
  //   subheader: 'MEMBERS',
  //   items: [
  //     { title: 'Influencers', path: '/influencers', icon: ICONS.influencers },
  //     { title: 'Advisors', path: '/advisors', icon: ICONS.advisors },
  //   ],
  // },
];
