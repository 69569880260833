import { useContext } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Typography, Avatar } from '@mui/material';
// components
import SignInGoogleButton from '../../../components/SignInGoogleButton';
// Context
import UserContext, { UserContextType } from '../../../contexts/UserContext';

const DEFAULT_AVATAR_USER_IMAGE = 'user.png';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
  transition: theme.transitions.create('opacity', {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

type Props = {
  isCollapse: boolean | undefined;
};

export default function NavbarAccount({ isCollapse }: Props) {
  const { userModel, googleSignInSuccess } = useContext<UserContextType>(UserContext);
  if (!userModel?.isSigned) {
    if (googleSignInSuccess) {
      return (
        <SignInGoogleButton
          googleSignInSuccess={googleSignInSuccess}
          theme="outline"
          useOneTap={true}
        />
      );
    }
    return null;
  }
  const avatarUrl = userModel?.avatar || `/images/avatars/${DEFAULT_AVATAR_USER_IMAGE}`;

  return (
    <Link underline="none" color="inherit">
      <RootStyle
        sx={{
          ...(isCollapse && {
            bgcolor: 'transparent',
          }),
        }}
      >
        <Avatar src={avatarUrl} />
        <Box
          sx={{
            ml: 2,
            transition: (theme) =>
              theme.transitions.create('width', {
                duration: theme.transitions.duration.shorter,
              }),
            ...(isCollapse && {
              ml: 0,
              width: 0,
            }),
          }}
        >
          <Typography variant="subtitle2" noWrap>
            {userModel?.name}
          </Typography>
          <Typography variant="caption" noWrap sx={{ color: 'text.secondary' }}>
            {userModel?.role}
          </Typography>
        </Box>
      </RootStyle>
    </Link>
  );
}
