import { useState, useEffect, useCallback } from 'react';
// Libs
import { useSnackbar } from 'notistack';
import { createWeb3Modal, defaultConfig, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { GoogleOAuthProvider, CredentialResponse } from '@react-oauth/google';
// @mui
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
// routes
import Router from './routes';
// Context
import UserContext from './contexts/UserContext';
// Components
import UserModel from './@types/models/UserModel';
// API
import { googleAuthenticate } from './api/base';
import { apiGetCurrentUser } from './api/user';
// Utils
import { getAccessTokenCookieData } from './utils/cookie';

// ----------------------------------------------------------------------

const googleClientId = '829588773915-fj6e8ir03fd1an8dr3v48ha7b7vjrloj.apps.googleusercontent.com';

// ----------------------------------------------------------------------

const web3ProjectId = '20773a10d16e2f77cbe362558178268e';

// 2. Set chains
const mainnet = {
  chainId: 1,
  name: 'Ethereum',
  currency: 'ETH',
  explorerUrl: 'https://etherscan.io',
  rpcUrl: 'https://cloudflare-eth.com',
};

// 3. Create modal
const metadata = {
  name: 'Launch3',
  description: 'A New Era for Token Launches',
  url: 'https://launch3.io/',
  icons: [
    'https://launch3.io/favicon/google-120x120.png',
    'https://launch3.io/favicon/favicon-32x32.png',
  ],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata }),
  chains: [mainnet],
  projectId: web3ProjectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

// ----------------------------------------------------------------------

export default function Main(): React.ReactElement {
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { enqueueSnackbar } = useSnackbar();
  const [userModel, setUserModel] = useState<UserModel | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getUser = async () => {
    setLoading(true);
    const user = await apiGetCurrentUser(enqueueSnackbar);
    if (user && !user.isConnected) {
      user.isConnected = isConnected;
    }
    setUserModel(user);
    setLoading(false);
  };

  const googleSignInSuccess = (credentialResponse: CredentialResponse): void => {
    googleAuthenticate(credentialResponse).then(getUser);
  };

  // const fetchCurrentUserModel = useCallback(async () => {
  //   // const userModel = await apiGetCurrentLoggedUser(enqueueSnackbar);
  //   const userModel = {
  //     id: 'userId',
  //     name: 'Erhan',
  //     typename: 'user',
  //     createdAt: new Date().toISOString(),
  //     roles: ['Eak Digital'],
  //     address: address || null,
  //     chainId: chainId || null,
  //     isConnected,
  //     isSigned: false,
  //     // Functions
  //     googleSignInSuccess,
  //   };
  //   setUserModel(userModel);
  // }, [enqueueSnackbar, address, chainId, isConnected]);
  // useEffect(() => {
  //   fetchCurrentUserModel();
  // }, [fetchCurrentUserModel]);

  useEffect(() => {
    // TODO.
    const u: UserModel = Object.assign({}, userModel || {}) as UserModel;
    u.isConnected = isConnected;
    u.chainId = chainId;
    u.address = address;
    setUserModel(u);
  }, [address, chainId, isConnected]);

  useEffect(() => {
    const authToken = getAccessTokenCookieData();
    if (authToken) {
      getUser();
    }
  }, []);

  return (
    <GoogleOAuthProvider key={userModel?.id} clientId={googleClientId}>
      <UserContext.Provider value={{ userModel, googleSignInSuccess }}>
        <Router userModel={userModel} />
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </UserContext.Provider>
    </GoogleOAuthProvider>
  );
}
