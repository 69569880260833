import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
  square?: boolean;
}

export default function Logo({ disabledLink = false, square = false, sx }: Props) {
  const logo = (
    // <Box sx={{ width: 220, ...sx }}></Box>
    <Box sx={sx}>
      <img alt="Logo" src={'/logo/' + (square ? 'logo.svg' : 'logo-text.svg')} />
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
