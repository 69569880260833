import { useState } from 'react';
// @mui
import { Stack, Button, Typography } from '@mui/material';
// assets
// import { DocIllustration } from '../../../assets';
// componenets
import ContactPopover from './ContactPopover';

// ----------------------------------------------------------------------

type Props = {
  isConnected: boolean;
};

export default function NavbarDocs({ isConnected }: Props) {
  //state
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
  return (
    <Stack
      spacing={2}
      sx={{
        px: 4,
        pb: 4,
        mt: 4,
        width: 1,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        display: 'flex',
      }}
    >
      <div>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          Need help?
        </Typography>
      </div>
      <Button
        size="small"
        variant="outlined"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
          setPopoverAnchorEl(event.currentTarget);
        }}
      >
        Contact Us
      </Button>
      <ContactPopover
        open={Boolean(popoverAnchorEl)}
        anchorEl={popoverAnchorEl}
        closePopup={() => setPopoverAnchorEl(null)}
      />
    </Stack>
  );
}
