import Cookies from 'js-cookie';

export const ACCESS_TOKEN_NAME = 'access_token';

export const setCookieData = (name: string, value: string, expireTime: number) =>
  Cookies.set(name, value, { expires: expireTime });

export const setAccessTokenCookieData = (token: string, expiresInDays: number) =>
  setCookieData(ACCESS_TOKEN_NAME, token, expiresInDays);

export const getCookieData = (cookieName: string): string | undefined => Cookies.get(cookieName);

export const getAccessTokenCookieData = (): string | undefined => Cookies.get(ACCESS_TOKEN_NAME);

export const deleteCookieData = (cookieName: string): void => Cookies.remove(cookieName);

export const deleteAccessTokenCookie = (): void => Cookies.remove(ACCESS_TOKEN_NAME);
