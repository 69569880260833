import { useWeb3Modal } from '@web3modal/ethers5/react';
// @mui
import Button from '@mui/material/Button';
// @mui/icons
import LoginIcon from '@mui/icons-material/Login';

type Props = {
  asLogin?: boolean;
  address?: string | null | undefined;
};

export default function ConnectWalletButton({ asLogin, address }: Props) {
  // 4. Use modal hook
  const { open } = useWeb3Modal();

  let label = asLogin ? 'Sign in with Wallet' : 'Connect Wallet';
  if (address) {
    label = `Connected ${address?.substring(0, 8)}`;
  }
  return (
    <Button
      variant={asLogin || !address ? 'contained' : 'outlined'}
      fullWidth={Boolean(asLogin)}
      size={asLogin ? 'large' : 'medium'}
      onClick={() => open()}
      startIcon={address ? undefined : <LoginIcon />}
    >
      {label}
    </Button>
  );
  // return (
  //   <>
  //     <button onClick={() => open()}>Open Connect Modal</button>
  //     <button onClick={() => open({ view: 'Networks' })}>Open Network Modal</button>
  //   </>
  // );
}
